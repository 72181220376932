






















































































































































































import { Vue, Component } from "vue-property-decorator";
import { isInStandaloneMode } from "@/plugins/utils";
import {
    mdiCheckCircle,
    mdiExportVariant,
    mdiNumeric1Circle,
    mdiNumeric2Circle,
    mdiNumeric3Circle,
    mdiNumeric4Circle,
} from "@mdi/js";
import BackButton from "@/components/BackButton.vue";
import { isWebView } from "@/plugins/utils";

@Component({
    components: { BackButton },
})
export default class InstallSafari extends Vue {
    oneIcon: string = mdiNumeric1Circle;
    shareIcon: string = mdiExportVariant;
    twoIcon: string = mdiNumeric2Circle;
    tickIcon: string = mdiCheckCircle;
    threeIcon: string = mdiNumeric3Circle;
    fourIcon: string = mdiNumeric4Circle;
    mounted() {
        if (isInStandaloneMode() && !isWebView()) {
            this.$root.$emit(
                this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                "Your are already using the app."
            );
            this.$router.push({ name: this.$constants.ROUTE_NAMES.HOME });
        }
    }
}
